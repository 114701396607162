import { Typography } from "@material-tailwind/react";
 
export default function FooterWithLogo() {
  return (
    <footer className="w-full bg-white p-4">
      {/* <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-white text-center md:justify-between">
        <img src="https://www.tranzita.com/images/logo-dark.png" alt="logo-ct" className="w-24" />
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              About Us
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              License
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              Contribute
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              Contact Us
            </Typography>
          </li>
        </ul>
      </div>
      <hr className="my-6 border-blue-gray-50" /> */}
      <Typography color="blue-gray" className="text-center font-normal">
        &copy; 2024 <span><a href="https://www.tranzita.com/" className="text-blue-600">Tranzita System</a></span>
      </Typography>
    </footer>
  );
}