import './App.css';
import  LoginForm  from './Pages/Login';
import Transmate from './Pages/Transmate';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoutes from './PrivateRoutes';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-8V12DDZC9F');

function App() {
  // const is_auth = localStorage.getItem('is_auth')
    return (
       <div>
        <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<LoginForm />} />
              <Route element={<PrivateRoutes/>} >
                <Route path='/home' element={<Transmate/>} />
              </Route>
              <Route path='*' element={<>Not Found, 404</>} />
            </Routes>
        </BrowserRouter>
       </div>
        
      );
   
}

export default App;
