import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

export default function PrivateRoutes() {
    // const navigate = useNavigate();
    const location = useLocation();

    let is_auth = localStorage.getItem('is_auth');
    console.log(is_auth);
    if(JSON.parse(is_auth)){
        // console.log('in outlet');
        return <Outlet />
    }
    if (location.pathname === '/' && is_auth) {
        return <Outlet />;
    }
    else{
        console.log('in root');
        return <Navigate to='/' replace />
    }
}