import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { Helmet } from 'react-helmet';
// import ReactGA from "react-ga4";

// import DocumentMeta from 'react-document-meta';


// const meta = {
//   title: 'Transmate',
//   description: 'Transmate by tranzita ',
//   // canonical: 'http://example.com/path/to/page',
//   meta: {
//       charset: 'utf-8',
//       name: {
//           keywords: 'react,meta,document,html,tags'
//       }
//   }
// }


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>

    <App />
  </React.StrictMode>
);



