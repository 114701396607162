import React, { Fragment, useState } from "react";
import FooterWithLogo from "../Component/Footer";
import SidebarWithLogo from "../Component/Sidebar";
import "../index.css";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import axios from "axios";
import Stack from "@mui/material/Stack";
import { DropzoneArea } from "material-ui-dropzone";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ReactGA from 'react-ga4';

import Lottie from "lottie-react";
import PlayBackAnimation from "../Assets/Animation - 1710327205487.json";
import { useNavigate } from "react-router-dom";
// import { getDatabase, ref, onValue } from 'firebase/database';
import { GlobeAltIcon } from "@heroicons/react/24/solid";


import { getDatabase, ref, onValue } from "firebase/database";



const CLOUD_FUNCTION = "https://api-transmate-v1-x6vzvzgmpa-uc.a.run.app";

// const CLOUD_FUNCTION = "http://127.0.0.1:5001/trans-mate/us-central1/api_transmate_v1";

const user = {
  name: "Vivek",
  email: "viv@example.com",
  imageUrl:
    "https://toppng.com/uploads/preview/cool-avatar-transparent-image-cool-boy-avatar-11562893383qsirclznyw.png",
};

const userNavigation = [
  { name: "Sign out", href: "#" },
];

const quickAccess = [
  {
    id: 1,
    to: "Polish",
    from: "English",
    to_code: "pl",
    from_code: "en",
    isActive: false,
  },
  {
    id: 2,
    to: "German",
    from: "English",
    to_code: "de",
    from_code: "en",
    isActive: false,
  },
  {
    id: 3,
    to: "French",
    from: "English",
    to_code: "fr",
    from_code: "en",
    isActive: false,
  },
];

const langOpts = [
  { id: 1, lang: "English", lang_code: "en" },
  { id: 2, lang: "Russian", lang_code: "ru" },
  { id: 3, lang: "Polish", lang_code: "pl" },
  { id: 4, lang: "German", lang_code: "de" },
  { id: 5, lang: "French", lang_code: "fr" },
];



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Transmate() {
  ReactGA.initialize('G-8V12DDZC9F');


  const [uploadedFileName, setUploadedFileName] = useState("");
  const [selectedInputLanguage, setSelectedInputLanguage] = useState("pl");
  const [selectedOutputLanguage, setSelectedOutputLanguage] = useState("en");
  const [showPdfUrl, setShowPdfUrl] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [inputPdfUrl, setInputPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [quickAccessSelected, setQuickAccessSelected] = useState(false);
  const [selectedIndex, setselectedIndex] = useState([]);

  const handleInputLanguageChange = (event) => {
    console.log(event.target.value);
    setSelectedInputLanguage(event.target.value);
  };

  const handleOutputLanguageChange = (event) => {
    console.log(event.target.value);
    setSelectedOutputLanguage(event.target.value);
  };

  const [InputBytePdf, setInputBytePDf] = useState("");
  const handleOriginalPDF = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      setUploadedFileName(file.name);
      const reader = new FileReader();
      toast.success(`File ${file.name} uploaded successfully`, {
        autoClose: 3000,
      });

      reader.onload = async () => {
        console.log(reader.result.split(",")[1]);
        setInputPdfUrl(reader.result);
        setInputBytePDf(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleRefresh = () => {
    setInputPdfUrl(null);
    setPdfUrl(null);
    setUploadedFileName("");
    toast.info("File removed successfully", { autoClose: 300 });
  };


 



  const handleTranslate = () => {
    
    // const db = getDatabase();
    // const starCountRef = ref(db, 'per_user_limiter/u_u_002/u/');
    // onValue(starCountRef, (snapshot) => {
    //   const data = snapshot.val();
    //   // updateStarCount(postElement, data);
    //   console.log('db',data);
    // });



    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);


    const inputData = {
      inputLanguage: selectedInputLanguage,
      outputLanguage: selectedOutputLanguage,
      pdfBuffer: InputBytePdf,
      u_id: localStorage.getItem("sessionUser"),
    };

    axios
      .post(CLOUD_FUNCTION, inputData)
      .then((response) => {
        console.log("line no 139",response);
        if (response.status === 200)  {
            if(response.data.err) {
                let {err} = response.data;
                if (err === 'AxiosError: Request failed with status code 400') {
                    toast.error('ERROR:: MAX_PDF_PAGE_NUM: 2', {autoClose :  1000});
                }

                if (err === 'AxiosError: Request failed with status code 403') {
                    toast.error('Something Went Wrong', {autoClose :  1000});
                }
            } else {
                toast.success("Process Completed", { autoClose: 200 });
                ReactGA.event({
                  category: 'API Calls',
                  action: 'Translation Success',
                  label: `<span class="math-inline">\{selectedInputLanguage\}\-</span>{selectedOutputLanguage}`
                });
                const byteStreamOutputs = response.data.documentTranslation.byteStreamOutputs;
                const mimeType = response.data.documentTranslation.mimeType;
                const byteCharacters = atob(byteStreamOutputs[0]);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: mimeType });
                const url = URL.createObjectURL(blob);
                setPdfUrl(url);
                setShowPdfUrl(true);
                setLoading(false);
            }
        }
    
        
      })
      .catch((error) => {
        if (error.response.status === 429) {
            toast.error("API Limit Exceeded", { autoClose: 3000 });
            ReactGA.event({
              category: 'API Calls',
              action: 'API LIMIT EXCEEDED'
            });
          }
          else if(error.response.status === 500) {
            toast.error("Internal Server Error", { autoClose: 3000 });
          } 
        // toast.error("Error while translating the PDF", { autoClose: 3000 });
        console.error("Error:", error);
      });
  };

  const handleClick = (data, id) => {
    setQuickAccessSelected(data);
    setselectedIndex(id);
  };

  let navigate = useNavigate();

  function logout(){
    localStorage.setItem('is_auth',false);
    navigate('/');
  }

  return (
    <>
      <div className="min-h-full">
        <Disclosure
          as="nav"
          className="bg-stone-200 md:bg-[#FFFFFF] w-full h-20   border border-spacing-3 rounded-base "
        >
          {({ open }) => (
            <>
              <div className="max-w-full p-1">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex justify-start align-top ">
                    <div className="flex-shrink-0 p-3">
                      <h2 className="overlock-sc-regular text-neutral-800  text-4xl font-semibold rounded-sm animate-text bg-gradient-to-r from-teal-500 via-purple-500 to-orange-500 bg-clip-text text-transparent">
                        TransMate{" "}
                      </h2>
                      <span className="text-xl text-gray-500">By Tranzita</span>
                    </div>
                  </div>

                  <div className="hidden md:block">
                    <div className="p-2">
                      <Menu as="div" className="">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="" />
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={user.imageUrl}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name} onClick={logout}>
                                {({ active }) => (
                                  <p
                                    href={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100 cursor-pointer" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </p>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>

        <main className="flex flex-row sm:[-40px]">
          
          {/* Side bar start */}
          <div className=""> 
          <SidebarWithLogo />
          </div>
          {/* Side bar end */}

          <div className="header w-full flex justify-center mt-3">
            <div className="w-[96%] my-6">
              {/* Quick Access start */}
              <Stack direction="row" spacing={2}>
                <h2 className="font-semibold text-xl">Quick Access &nbsp;</h2>
                {quickAccess.map((element, index) => {
                  return (
                    <span
                      variant={"outlined"}
                      onClick={() => handleClick(element, index)}
                      className={`w-[12%] text-lg text-center  font-medium me-2 px-2.5 py-0.5 rounded-full border ${
                        index === selectedIndex
                          ? "border-blue-500 text-blue-500"
                          : "border-gray-300 text-black"
                      } hover:text-blue-400 hover:border-blue-400 cursor-pointer `}
                      key={index}
                    >
                      {`${element.to}-${element.from}`}
                    </span>
                  );
                })}
              </Stack>
              {/* Quick Access end */}

              {/* Main APP start */}
              <div className="mt-10">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
                  {/* Left: PDF Upload Start */}
                  <div className="bg-[#F7F7F7] px-4 py-8 rounded-2xl">
                    <div className="flex flex-col w-full gap-10">
                      <div className="flex flex-row justify-start gap-5 items-start">
                        <label className="text-sm text-gray-600 px-4 py-3">
                          From
                        </label>
                        <GlobeAltIcon className="w-6 flex-shrink-0 ml-3 md:ml-36 lg:ml-64"/>

                        <select
                          value={selectedInputLanguage}
                          onChange={handleInputLanguageChange}
                          className="form-select appearance-none border-2 bg-white border-gray-100 px-4 py-3 rounded-2xl w-[400px] focus:outline-none focus:ring focus:border-blue-500"
                          aria-label="Select input language"
                        >
                          
                          {langOpts.map((element, index) => {
                            return (
                              <option key={index} value={element.lang_code}>
                                {element.lang}
                              </option>
                            );
                          })}
                        </select>
                        
                        {uploadedFileName && (

                                                      
                            <button
                              type="button"
                              className='w-[30%] text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-3 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"'
                              onClick={handleRefresh}
                            >
                              Remove
                            </button>
                        
                        )}
                      </div>

                      {!inputPdfUrl && (
                        <DropzoneArea
                          onChange={handleOriginalPDF}
                          acceptedFiles={["application/pdf"]}
                          dropzoneText="Drag and drop a PDF file here or click to browse"
                          showPreviews={false}
                          showPreviewsInDropzone={false}
                          maxFileSize={3000000}
                          filesLimit={1}
                          showAlerts={true}
                        />
                      )}

                      {inputPdfUrl && (
                        <div className="w-full">
                          <iframe
                            src={inputPdfUrl}
                            width="100%"
                            height="600px"
                            title="Input PDF"
                          ></iframe>
                        </div>
                      )}
                    </div>

                    
                  </div>
                  {/* Left: PDF Upload End */}

                  {/* Right: Translated PDF Start */}

                  <div className="bg-[#F7F7F7] px-4 py-8 rounded-2xl">
                    <div className="flex flex-col w-full gap-10">
                      <div className="flex flex-row justify-start gap-5 items-start">
                        <label className="text-sm text-gray-600 px-4 py-3">
                          Into
                        </label>
                        <GlobeAltIcon className="w-6 flex-shrink-0 ml-3 md:ml-36 lg:ml-64"/>
                        <select
                          value={selectedOutputLanguage}
                          onChange={handleOutputLanguageChange}
                          className="form-select appearance-none border-2 bg-white border-gray-100 px-4 py-3 rounded-2xl w-[400px] focus:outline-none focus:ring focus:border-blue-500"
                          aria-label="Select input language"
                        >
                    
                          {langOpts.map((element, index) => {
                            return (
                              <option key={index} value={element.lang_code}>
                                {element.lang}
                              </option>
                            );
                          })}
                        </select>
                        {uploadedFileName && (
                          <button
                            type="button"
                            className='w-[30%] text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-3 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"'
                            onClick={handleTranslate}
                          >
                            Translate
                          </button>
                        )}
                      </div>
                      {!pdfUrl && (
                        <div className="mt-10 w-full h-[400px] flex items-center justify-center rounded-3xl">
                          {loading ? (
                            <Lottie
                              animationData={PlayBackAnimation}
                              loop={true}
                              start={true}
                              style={{
                                height: "50%",
                                width: "50%",
                              }}
                            />
                          ) : (
                            <div className="flex items-center justify-center">
                              <Lottie
                                animationData={PlayBackAnimation}
                                loop={false}
                                start={false}
                                style={{
                                  height: "50%",
                                  width: "50%",
                                }}
                              />
                              <span className="text-gray-800">
                                <h2 className="font-base text-xl p-2">
                                  Translated PDF will be rendered here
                                </h2>
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      {pdfUrl && (
                        <div className="w-full">
                          <iframe
                            src={pdfUrl}
                            width="100%"
                            height="600px"
                            title="Translated PDF"
                          ></iframe>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Right: Translated PDF End */}

                  {/* ToastBox */}
                  <ToastContainer />
                </div>
              </div>
              {/* Main APP end */}

              {/* Footer starts */}
              <div className="mt-20">
                <FooterWithLogo />
              </div>
              {/* Footer end */}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
