import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyCdcdk-n8glyF0JdN4uwgHdhTP2V82ZA04",
  authDomain: "trans-mate.firebaseapp.com",
  projectId: "trans-mate",
  storageBucket: "trans-mate.appspot.com",
  messagingSenderId: "348459930309",
  appId: "1:348459930309:web:a3a764a5c62537ae1956ac",
  measurementId: "G-KLKP1RZD4P"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export default db;