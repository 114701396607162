import React from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,

  Alert,
} from "@material-tailwind/react";
import {
  HomeIcon,
} from "@heroicons/react/24/solid";
import ReactGA from 'react-ga4';

 
function  SidebarWithLogo() {
 
 
  return (
    <Card className="h-full 2xl:mt-[-27%] mt-[-27%] w-[300px] p-2 border shadow-blue-gray-900/12 hidden sm:block">
     
      <List className=" ">
    
        <ListItem className="p-4 bg-blue-400  border border-spacing-3 rounded-full text-white">
          <ListItemPrefix className="px-2">
            <HomeIcon className="h-5 w-5 " />
          </ListItemPrefix >
          Home
        </ListItem>
        
      </List>

      <Alert className="mt-[450px] max-w-[250px]">
        <Typography variant="h6" className="mb-8 mt-4">
          About Transmate
        </Typography>
        <Typography variant="small" className="font-normal opacity-80">
          Transmate simplifies PDF translation, facilitating seamless conversion between languages, streamlining the process for efficient and effective multilingual communication.
        </Typography>
        <div className="mt-6 mb-6 flex gap-3">
          <Typography as="p" href="#" variant="small" className="font-medium">
            Learn More
          </Typography>
        </div>
      </Alert>

    </Card>
  );
}


export default SidebarWithLogo;